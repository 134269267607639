var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-100 p-5 text-center" }, [
    _c("p", { staticStyle: { "font-size": "2rem", "font-weight": "700" } }, [
      _vm._v("404"),
    ]),
    _c("p", { staticStyle: { "font-size": "1.5rem", "font-weight": "500" } }, [
      _vm._v(_vm._s(_vm.$t("shared.notFound"))),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }